import React, { useState } from "react";

const CryptoAnimation = () => {
  return (
    <div id="images" width="380px" height="500px" viewBox="0 0 837 1045">
      <img
        id="Polygon-0"
        stroke="#007FB2"
        strokeWidth="6"
        width={100}
        src={process.env.REACT_APP_USDC_IMAGE}
      ></img>
      <img
        id="Polygon-1"
        stroke="#007FB2"
        strokeWidth="6"
        height={300}
        src={process.env.REACT_APP_ETHEREUM_IMAGE}
      ></img>
      <img
        id="Polygon-2"
        stroke="#007FB2"
        strokeWidth="6"
        width={75}
        src={process.env.REACT_APP_BITCOIN_IMAGE}
      ></img>
      <br />
      <img
        id="Polygon-3"
        stroke="#007FB2"
        strokeWidth="6"
        width={100}
        src={process.env.REACT_APP_BINANACE_IMAGE}
      ></img>
      <img
        id="Polygon-4"
        stroke="#007FB2"
        strokeWidth="6"
        width={150}
        src={process.env.REACT_APP_POLYGON_IMAGE}
      ></img>
      <br />
      <img
        id="Polygon-5"
        stroke="#007FB2"
        strokeWidth="6"
        width={100}
        src={process.env.REACT_APP_SOLANA_IMAGE}
      ></img>
    </div>
  );
};

export default CryptoAnimation;
