
import {
    BrowserRouter as Router,
    Link,
    
  } from "react-router-dom";

export default function End_user(props){
  
    return (
        <div className="container-fluid" >
        <div
            className="col d-flex justify-content-center "
            style={{ paddingTop: "180px" }}>
            <div className="card w-50 " style={{backgroundColor:"black", color:"whitesmoke"}}>
                <div className="card-body my-4">
                <h5 className="card-title " style={{ fontWeight: "bold" }}>
                    OOOPs!!!
                </h5>
                <p className="card-text">
                    You are not owner of this marketplace. Please connect to {props.address}{" "}
                    this address Or you can buy NFts from marketplace by clicking
                    below button
                </p>
                <Link className="btn btn-primary action-button" to="/" >
                    Marketplace
                </Link>
            </div>
        </div>
      </div>
    </div>)
}