//require('dotenv').config();
const axios = require("axios");
const FormData = require("form-data");

const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
const IPFS_GATEWAY_URL = process.env.REACT_APP_IPFS_GATEWAY_URL;
const SERVER_BASE_URL = process.env.REACT_APP_IPFS_BASE_URL;
const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER

// This funcction uploads JSON to IPFS.
export const uploadJSONToIPFS = async (JSONBody) => {
  const url = SERVER_BASE_URL + "uploadJsonToIpfs";
  //making axios POST request to our server ⬇️
  return axios({
    method: "post",
    url: url,
    data: JSONBody,
    // headers: {
    //   "Content-Type": "application/json",
    //   authorization: AUTH_KEY,
    // },
  })
    .then(function (response) {
      return {
        success: true,
        pinataURL: IPFS_GATEWAY_URL + response.data[0].hash,
      };
    })
    .catch(function (error) {
      axios.get(BASE_URL_SERVER + "errorlog?er=" + error);
      return {
        success: false,
        message: error.message,
      };
    });
};

// This function upload file to IPFS.
export const uploadFileToIPFS = async (file) => {
  const url = SERVER_BASE_URL + "uploadFileToIpfs";

  let data = new FormData();
  data.append("file", file);

  //making axios POST request to Server⬇️
  return axios({
    method: "post",
    url: url,
    data: data,
    // headers: {
    //   "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    //   authorization: AUTH_KEY,
    // },
  })
    .then(function (response) {
      return {
        success: true,
        pinataURL: IPFS_GATEWAY_URL + response.data[0].hash,
      };
    })
    .catch(function (error) {
      axios.get(BASE_URL_SERVER + "errorlog?er=" + error);
      return {
        success: false,
        message: error.message,
      };
    });
};
