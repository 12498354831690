import { useEffect, useState } from "react";
import Web3 from "web3";
import { useLocation } from "react-router";
import axios from "axios";
import NFTMarketplace from "artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json";
import { uploadFileToIPFS } from "util/Ipfs-file-upload";
import Hand from "svg-images/hand";

const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

export default function AdminPannel() {
  const [formParams, updateFormParams] = useState({
    url: "",
    username: "",
    currentAddress: "",
    img: "",
    permission: "",
  });
  const [connected, toggleConnect] = useState(false);
  const location = useLocation();
  const [network, updateNetwork] = useState({});
  const [iscloud, checkIscloud] = useState(false);

  async function setEnvironment() {
    await axios.get(BASE_URL_SERVER + "environment-type").then((response) => {
      checkIscloud(response.data.status);
    });
  }

  function updateButton() {
    const ethereumButton = document.querySelector(".enableEthereumButton");
    ethereumButton.textContent = "Connected";
    ethereumButton.classList.remove("bg-red-500");
    ethereumButton.classList.add("bg-green-500");
  }

  async function getAddress() {
    const ethers = require("ethers");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    provider._networkPromise.then((data) => {
      updateNetwork(data);
    });

    updateFormParams({
      ...formParams,
      currentAddress: address,
    });
  }

  async function isUrlvalid(e) {
    e.preventDefault();

    const res = await axios.get(BASE_URL_SERVER + "read?que=" + formParams.url);

    if (res.data) {
      updateFormParams({
        ...formParams,
        url: "",
      });
    } else {
    }
  }

  async function OnChangeFile(e) {
    e.preventDefault();

    var file = e.target.files[0];

    try {
      //upload the file to IPFS
      const response = await uploadFileToIPFS(file);
      if (response.success === true) {
        updateFormParams({
          ...formParams,
          img: response.pinataURL,
        });
      }
    } catch (e) {
      await axios.get(BASE_URL_SERVER + "errorlog?er=" + e);
    }
  }

  // Function for connecting metamask wallet.
  async function connectWebsite() {
    await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(() => {
        getAddress();
        window.location.replace(location.pathname);
      });

    toggleConnect(true);
  }

  async function rights(e) {
    e.preventDefault();

    await axios.get(BASE_URL_SERVER + "updateAdmin?admin=" + e.target.value);
  }

  async function handlePermission(e) {
    updateFormParams({
      ...formParams,
      permission: e.target.value,
    });
  }

  async function deployContract(e) {
    e.preventDefault();

    const web3 = new Web3(window.ethereum);

    const ABI = NFTMarketplace.abi;
    const bytecode = NFTMarketplace.bytecode;

    const contract = new web3.eth.Contract(ABI);
    web3.eth.getAccounts().then((accounts) => {
      // address that will deploy smart contract
      let mainAccount = accounts[0];

      contract
        .deploy({ data: bytecode, arguments: [1, false] })
        .send({ from: mainAccount, gas: 4700000 })
        .on("receipt", async (receipt) => {
          const contract_address = receipt.contractAddress;

          if (iscloud) {
            const { url, username, currentAddress, img, permission } =
              formParams;

            let jsonBody = {
              url,
              username,
              contractAddress: contract_address,
              currentAddress,
              img,
              permission,
              network: network.chainId,
            };

            let apiUrl = BASE_URL_SERVER + "demo-collection";

            await axios
              .post(apiUrl, jsonBody, {})
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
          } else {
            await axios.get(
              BASE_URL_SERVER +
                "saveContractAddress?contract_address=" +
                receipt.contractAddress
            );
          }
        });
    });
  }

  // Used for checking if wallet is connected or not.
  async function isConnected() {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (accounts.length) {
      getAddress();
      toggleConnect(true);
    } else {
      toggleConnect(false);
    }
  }

  useEffect(() => {
    isConnected();
    setEnvironment();
  }, [network, connected, formParams]);

  return (
    <div>
      {connected ? (
        iscloud ? (
          <div className="container" style={{ marginTop: "150px" }}>
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <div className="card welcome-card">
                  <div className="card-body">
                    <h5
                      className="text-center font-bold my-5 card-heading"
                      style={{ marginTop: "20px" }}
                    >
                      <Hand />
                      Deploying NFT Marketplace on {network.name} network with
                      Wallet address
                      <p
                        className="text-truncate"
                        style={{ maxWidth: "150px", margin: "auto" }}
                      >
                        {formParams.currentAddress}{" "}
                      </p>
                    </h5>

                    <div className="select mb-5">
                      <select
                        name="format"
                        id="format"
                        onChange={(e) => handlePermission(e)}
                      >
                        <option selected disabled>
                          Choose who can mint
                        </option>
                        <option value="Admin">Admin</option>
                        <option value="User">End user</option>
                      </select>
                    </div>

                    <label>
                      upload file
                      <input
                        onChange={OnChangeFile}
                        id="input-file"
                        type="file"
                      ></input>
                    </label>

                    <input
                      placeholder="Enter url"
                      type="text"
                      value={formParams.url}
                      onChange={(e) =>
                        updateFormParams({
                          ...formParams,
                          url: e.target.value,
                        })
                      }
                    ></input>
                    <button onClick={isUrlvalid}>check</button>
                    <input
                      placeholder="Enter username"
                      type="text"
                      value={formParams.username}
                      onChange={(e) =>
                        updateFormParams({
                          ...formParams,
                          username: e.target.value,
                        })
                      }
                    ></input>

                    <button
                      className="btn btn-primary nav-btn"
                      onClick={deployContract}
                      style={{ padding: "15px", width: "100%" }}
                    >
                      Setup your marketplace
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
        ) : (
          <div className="container" style={{ marginTop: "150px" }}>
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <div className="card welcome-card">
                  <div className="card-body">
                    <h5
                      className="text-center font-bold my-5 card-heading"
                      style={{ marginTop: "20px" }}
                    >
                      <Hand />
                      Deploying NFT Marketplace on {network} network with Wallet
                      address
                      <p
                        className="text-truncate"
                        style={{ maxWidth: "150px", margin: "auto" }}
                      >
                        {formParams.currentAddress}{" "}
                      </p>
                    </h5>

                    <div className="select mb-5">
                      <select name="format" id="format" onChange={rights}>
                        <option selected disabled>
                          Choose who can mint
                        </option>
                        <option value="Admin">Admin</option>
                        <option value="User">End user</option>
                      </select>
                    </div>

                    <button
                      className="btn btn-primary nav-btn"
                      onClick={deployContract}
                      style={{ padding: "15px", width: "100%" }}
                    >
                      Setup your marketplace
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
        )
      ) : (
        <div className="container" style={{ marginTop: "150px" }}>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div className="card welcome-card">
                <div className="card-body">
                  <h5
                    className="text-center font-bold my-5 card-heading"
                    style={{ marginTop: "20px" }}
                  >
                    <Hand />
                    Welcome to miniorange NFT Marketplace.
                  </h5>

                  <button
                    type="button"
                    className="btn btn-primary nav-btn"
                    onClick={connectWebsite}
                    style={{ marginLeft: "50px" }}
                  >
                    Connect Wallet
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      )}
    </div>
  );
}
