import React from "react";
import CheckLogo from "svg-images/check";

function Icon({ state }) {
  return (
    <>
      {state === "loading" ? (
        <div className="spinner-border" role="status"></div>
      ) : (
        <CheckLogo color={state ? "green" : "currentColor"} />
      )}
    </>
  );
}

function ApprovalAndListingModal(props) {
  return (
    <>
      <div>
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            style={{ marginTop: "12%" }}
            className="modal-dialog"
            role="document"
          >
            <div
              className="modal-content shadow-lg"
              style={{ backgroundColor: "black", borderRadius: "1.5rem" }}
            >
              <div
                style={{ margin: "auto", borderBottom: "none" }}
                className="modal-header"
              >
                <h3 className="modal-title" style={{ color: "white" }}>
                  Listing your NFT
                </h3>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-row mb-4">
                  <div style={{ marginRight: "30px" }}>
                    <Icon state={props.approvalModal} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4 style={{ color: "white" }}>Approval</h4>
                    <p style={{ fontSize: "14px", color: "#818181" }}>
                      This transaction is conducted only once per collection.
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row mt-4">
                  <div style={{ marginRight: "30px" }}>
                    <Icon state={props.listingModal} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4 style={{ color: "white" }}>List NFT</h4>
                    <p style={{ fontSize: "14px", color: "#818181" }}>
                      Confirm transaction to put on sale for a fixed price.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backdropFilter: "blur(20px)",
            backgroundColor: "transparent",
          }}
          className="modal-backdrop"
          onClick={props.toggleDialog}
        ></div>
      </div>
    </>
  );
}

export default ApprovalAndListingModal;
