import { useEffect, useState, useRef } from "react";
import { uploadFileToIPFS, uploadJSONToIPFS } from "util/Ipfs-file-upload";
import axios from "axios";
import Swal from "sweetalert2";
import End_user from "./userNotAllowed";
import Abi from "artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json";
import bigInt from "big-integer";
import NFTTile from "components/layouts/NFTTile";
import { useContract, useSigner, useAccount } from "wagmi";
import { checkError } from "util/metamaskErrors";
import PageNotFound from "components/layouts/PageNotFound";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import ConfirmTransactionModal from "./ConfirmTransactionModal";
const ethers = require("ethers");
const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

export default function MintNFT() {
  const [formParams, updateFormParams] = useState({
    image: "",
    name: "",
    description: "",
    price: "",
    lazyMint: false,
  });
  const { address, isConnected } = useAccount();
  const [loader, updateloader] = useState(false);
  const [ownercheck, updateCheck] = useState(false);
  const [admin, updateAdmin] = useState(false);
  const [isPolygon, updateIsPolygon] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const MarketplaceJSON = useRef({});
  const [pageNotFound, setPageNotFound] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState(false);
  const [transactionModalHeading, setTransactionModalHeading] = useState();
  const [confirmTransactionMessage, setConfirmTransactionMessage] =
    useState("");
  const [transactionWaitMessage, setTransactionWaitMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { data: signer } = useSigner();
  const { openConnectModal } = useConnectModal();

  const contract = useContract({
    address: MarketplaceJSON.current.address,
    abi: Abi.abi,
    signerOrProvider: signer,
  });

  //This function uploads the NFT image to IPFS
  async function OnChangeFile(e) {
    e.preventDefault();
    setFormErrorMessage("");

    if (!e.target.value) {
      updateloader(false);
      return;
    }

    var file = e.target.files[0];
    updateloader(true);

    let ratio;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = async () => {
        ratio = img.width / img.height;
      };

      img.src = e.target.result;
    };
    reader.readAsDataURL(e.target.files[0]);

    if (e.target.files[0].type.split("/")[0] === "image") {
      try {
        //upload the file to IPFS
        const response = await uploadFileToIPFS(file);
        if (response.success === true) {
          if (ratio > 1 && ratio < 1.5) {
            document.getElementById("logo-btn").style.height = "320px";
          } else if (ratio <= 1) {
            document.getElementById("logo-btn").style.height = "370px";
          } else if (ratio == 1.5) {
            document.getElementById("logo-btn").style.height = "260px";
          } else if (ratio > 1.5 && ratio <= 2.5) {
            document.getElementById("logo-btn").style.height = "200px";
          } else if (ratio > 2.5) {
            document.getElementById("logo-btn").style.height = "160px";
          }

          updateFormParams((prevParams) => ({
            ...prevParams,
            image: response.pinataURL,
          }));
          updateloader(false);
        }
      } catch (e) {
        await axios.get(BASE_URL_SERVER + "errorlog?er=" + e);
      }
    } else {
      setFormErrorMessage(process.env.REACT_APP_IMAGE_UPLOAD_ERROR_MESSAGE);
      return;
    }
  }

  //This function uploads the metadata to IPFS
  async function uploadMetadataToIPFS() {
    const { name, description, image } = formParams;
    //Make sure that none of the fields are empty
    if (!name || !description || !image) {
      setFormErrorMessage(process.env.REACT_APP_INCOMPLETE_FORM_MESSAGE);
      return;
    }

    const nftJSON = {
      name,
      description,
      image: formParams.image,
    };

    try {
      //upload the metadata JSON to IPFS
      const response = await uploadJSONToIPFS(nftJSON);
      if (response.success === true) {
        return response.pinataURL;
      }
    } catch (e) {
      await axios.get(BASE_URL_SERVER + "errorlog?er=" + e);
    }
  }

  // This function list NFT.
  async function listNFT(e) {
    e.preventDefault();
    updateloader(true);

    const metadataURL = await uploadMetadataToIPFS();
    //Upload data to IPFS
    if (metadataURL) {
      if (formParams.price <= 0 && formParams.lazyMint) {
        setFormErrorMessage("Price should be greater than 0");
        updateloader(false);
        return;
      }

      if (address) {
        setOpenModal(true);
        setIsConfirmed("loading");
        setIsTransactionComplete("loading");
        setTransactionModalHeading("Creating your NFT");
        try {
          // If lazy minting checkbox is true it run lazyMint function
          if (formParams.lazyMint === true) {
            setConfirmTransactionMessage(
              "Please sign the transaction in your wallet"
            );
            setTransactionWaitMessage("NFT is getting created");

            const signerAddress = await signer.getAddress();
            const tokenId = signerAddress + Date.now();

            var voucher = await CreateLazyMintVoucher(
              tokenId,
              signer,
              MarketplaceJSON.current.address,
              metadataURL
            );

            await axios.post(BASE_URL_SERVER + "voucher", {
              voucher,
              signer: signerAddress,
            });

            setIsTransactionComplete(true);
          } else {
            setConfirmTransactionMessage(
              "Please confirm the transaction in your wallet"
            );
            setTransactionWaitMessage("NFT is getting created");
            //Actually create the NFT Token
            let transaction = await contract
              .connect(signer)
              .safeMint(metadataURL);
            setIsConfirmed(true);
            await transaction.wait();
          }
          setIsTransactionComplete(true);
          Swal.fire({
            title: `${process.env.REACT_APP_NFT_CREATED_MESSAGE}${formParams.name}!`,
            color: "white",
            icon: "success",
            background: "#34363d",
          });
          setIsConfirmed(false);
          setOpenModal(false);
          document.getElementById("logo-btn").style.height = "150px";
          updateloader(false);
          updateFormParams({
            image: "",
            name: "",
            description: "",
            price: "",
            lazyMint: false,
          });
        } catch (e) {
          checkError(e.code);
          updateloader(false);
          setIsConfirmed(false);
          setOpenModal(false);
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Not Connected !",
          color: "white",
          background: "#34363d",
          text: process.env.REACT_APP_WALLET_NOT_CONNECTED_MESSAGE,
          confirmButtonText: "Connect Wallet",
        }).then((result) => {
          if (result.isConfirmed) {
            openConnectModal();
          }
        });
        updateloader(false);
        setIsConfirmed(false);
        setOpenModal(false);
      }
    } else {
      updateloader(false);
      return;
    }
  }

  // Create and return a voucher for lazy mint
  async function CreateLazyMintVoucher(tokenId, signer, marketplace, uri) {
    try {
      const SIGNING_DOMAIN_NAME = "LazyNFT-Voucher";
      const SIGNING_DOMAIN_VERSION = "1";

      const chainId = await signer.getChainId();
      const contractAddress = marketplace;

      const domain = {
        name: SIGNING_DOMAIN_NAME,
        version: SIGNING_DOMAIN_VERSION,
        verifyingContract: contractAddress,
        chainId,
      };

      const types = {
        NFTVoucher: [
          { name: "tokenId", type: "uint256" },
          { name: "minPrice", type: "uint256" },
          { name: "uri", type: "string" },
        ],
      };
      const strippedtokenId = tokenId.substring(2);
      const newTokenId = bigInt(strippedtokenId, 16).toString();
      const minPrice = ethers.utils.parseUnits(formParams.price, "ether");
      const voucher = { tokenId, minPrice, uri };
      setIsConfirmed("loading");

      const signature = await signer._signTypedData(domain, types, voucher);

      const voucher_new = [
        newTokenId,
        minPrice.toString(10),
        voucher.uri,
        signature,
      ];
      setIsConfirmed(true);
      return voucher_new;
    } catch (err) {
      setIsConfirmed(false);
      setOpenModal(false);
      throw err;
    }
  }

  async function contractOwner() {
    let resp = await axios.get(BASE_URL_SERVER + "marketplace-data");

    if (resp.data) {
      MarketplaceJSON.current = resp.data;
      if (
        MarketplaceJSON.current.network ==
          Number(process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID) ||
        MarketplaceJSON.current.network ==
          Number(process.env.REACT_APP_POLYGON_CHAIN_ID)
      ) {
        updateIsPolygon(true);
      }
    } else {
      return;
    }
    if (MarketplaceJSON.current.contractType === "deployed") {
      let ownerAddress = MarketplaceJSON.current.ownerAddress;
      updateAdmin(ownerAddress);
      setPageNotFound(false);
      if (
        isConnected &&
        address.toUpperCase() != ownerAddress.toUpperCase() &&
        resp.data.admin != "Anyone"
      ) {
        updateCheck(true);
      }
    } else {
      setPageNotFound(true);
    }
  }
  useEffect(() => {
    if (!admin) {
      contractOwner();
    }
  }, [MarketplaceJSON, admin]);

  return (
    <>
      {pageNotFound !== null && (
        <div>
          {!pageNotFound ? (
            !ownercheck ? (
              <div className="container mb-5" style={{ marginTop: "130px" }}>
                <div className="row col-9" style={{ margin: "auto" }}>
                  <div className="col-1"></div>
                  <div className="col-6">
                    <div style={{ marginTop: "1rem" }}>
                      <h3
                        style={{
                          fontSize: "xxx-large",
                          color: "#e3e3e3",
                          fontWeight: "bold",
                        }}
                      >
                        Create NFT
                      </h3>
                    </div>
                    <form onSubmit={listNFT}>
                      <div className="form-group mt-4">
                        <label htmlFor="input-file" className="form-label">
                          Upload file<span className="required-symbol">*</span>
                        </label>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#cecece",
                            margin: "0",
                          }}
                        >
                          File types supported: JPG, PNG, JPEG, GIF, SVG, AVIF,
                          WebP. Max size: 100 MB
                        </p>
                        <br />
                        <input
                          className="form-control1 "
                          id="input-file"
                          type="file"
                          accept="image/*"
                          name="nft-image"
                          onChange={OnChangeFile}
                          hidden
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "70px",
                            alignItems: "center",
                          }}
                        >
                          <label
                            id="logo-btn"
                            htmlFor="input-file"
                            style={{
                              height: "150px",
                              width: "100%",
                              borderRadius: "1rem",
                            }}
                          >
                            {formParams.image ? (
                              <img
                                alt="image"
                                className="image-class"
                                width={300}
                                height={300}
                                src={formParams.image}
                                style={{ objectFit: "contain" }}
                              />
                            ) : (
                              <div id="logo-text">+</div>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="name" className="form-label">
                          NFT Name<span className="required-symbol">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control1 create-nft-form"
                          id="name"
                          placeholder="NFT Name"
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) => (e.target.placeholder = "NFT Name")}
                          disabled={loader ? true : false}
                          onChange={(e) => {
                            setFormErrorMessage("");
                            updateFormParams({
                              ...formParams,
                              name: e.target.value,
                            });
                          }}
                          value={formParams.name}
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="description" className="form-label">
                          NFT Description
                          <span className="required-symbol">*</span>
                        </label>
                        <textarea
                          rows={4}
                          type="text-area"
                          className="form-control1 create-nft-form"
                          id="description"
                          title=""
                          placeholder="Describe your NFT"
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) =>
                            (e.target.placeholder = "Describe your NFT")
                          }
                          disabled={loader ? true : false}
                          value={formParams.description}
                          onChange={(e) => {
                            setFormErrorMessage("");
                            updateFormParams({
                              ...formParams,
                              description: e.target.value,
                            });
                          }}
                          required
                        ></textarea>
                      </div>
                      <div className="form-group mt-4 row">
                        <label htmlFor="lazyMint" className="col-10 form-label">
                          Free Minting<span className="required-symbol"></span>
                        </label>
                        <div className=" col-2 btn-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value={formParams.lazyMint}
                            id="lazyMint"
                            checked={formParams.lazyMint}
                            disabled={loader ? true : false}
                            onClick={(e) => {
                              setFormErrorMessage("");
                              updateFormParams({
                                ...formParams,
                                lazyMint: !formParams.lazyMint,
                                price: "",
                              });
                            }}
                          />
                        </div>

                        {formParams.lazyMint && (
                          <div className="form-group mt-4" id="divcheck">
                            <label htmlFor="price" className="form-label">
                              Price<span className="required-symbol">*</span>
                            </label>
                            <input
                              required
                              className="form-control1 create-nft-form"
                              id="price"
                              type="number"
                              min={0}
                              step="any"
                              disabled={loader ? true : false}
                              placeholder={
                                isPolygon
                                  ? "Enter price in Matic"
                                  : "Enter price in Eth"
                              }
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) =>
                                (e.target.placeholder = isPolygon
                                  ? "Enter Price in MATIC"
                                  : "Enter Price in ETH")
                              }
                              value={formParams.price}
                              onChange={(e) => {
                                setFormErrorMessage("");
                                updateFormParams({
                                  ...formParams,
                                  price: e.target.value,
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mb-4 mt-4">
                        {formErrorMessage && (
                          <p style={{ color: "tomato", textAlign: "center" }}>
                            {formErrorMessage}
                          </p>
                        )}
                      </div>
                      <div className="row" style={{ marginTop: "2.5rem" }}>
                        {loader ? (
                          <div
                            className="dottet-loader"
                            style={{ margin: "auto", marginTop: "1.5rem" }}
                          ></div>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary nav-btn"
                            style={{ margin: "auto", width: "457px" }}
                          >
                            <span>Create NFT</span>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                  {openModal && (
                    <ConfirmTransactionModal
                      confirmTransaction={isConfirmed}
                      confirmTransactionMessage={confirmTransactionMessage}
                      transactionWait={transactionWaitMessage}
                      transactionComplete={isTransactionComplete}
                      transactionModalHeading={transactionModalHeading}
                    />
                  )}

                  <div className="col-5">
                    <div className="sticky-top">
                      <div style={{ marginLeft: "52px", marginTop: "6.5rem" }}>
                        <h5 style={{ color: "#cecece" }}>Preview</h5>
                      </div>
                      {!formParams.image ? (
                        <div
                          className="card-dark sticky-top"
                          style={{
                            border: "1px solid #080808",
                            width: "275px",
                            height: "325px",
                            marginTop: "1rem",
                            margin: "auto",
                          }}
                        >
                          <p
                            style={{
                              marginTop: "8rem",
                              textAlign: "center",
                              color: "rgb(150 150 150)",
                            }}
                          >
                            Upload a file <br /> to preview your NFT
                          </p>
                        </div>
                      ) : (
                        <NFTTile
                          data={formParams}
                          isPolygon={isPolygon}
                          Preview={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <End_user address={admin}></End_user>
            )
          ) : (
            <PageNotFound />
          )}
        </div>
      )}
    </>
  );
}
