import React from "react";
import CheckLogo from "svg-images/check";

function Icon({ state }) {
  return (
    <>
      {state === "loading" ? (
        <div className="spinner-border" role="status"></div>
      ) : (
        <CheckLogo color={state ? "green" : "currentColor"} />
      )}
    </>
  );
}

export default function ConfirmTransactionModal(props) {
  return (
    <>
      <div>
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            style={{ marginTop: "15%", maxWidth: "420px" }}
            className="modal-dialog"
            role="document"
          >
            <div
              className="modal-content shadow-lg"
              style={{ backgroundColor: "black", borderRadius: "1.5rem" }}
            >
              <div
                style={{
                  margin: "auto",
                  borderBottom: "none",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="modal-header"
              >
                <div>
                  <h3
                    style={{
                      fontSize: "24px",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {props.transactionModalHeading}
                  </h3>
                </div>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-row mb-4 ml-2">
                  <div style={{ marginRight: "35px" }}>
                    <Icon state={props.confirmTransaction} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      marginTop: "0",
                    }}
                  >
                    {/* <h4 style={{ color: "white" }}>Confirm Transaction</h4> */}
                    <h5
                      style={{
                        fontSize: "16px",
                        color: "#818181",
                        fontWeight: "600",
                      }}
                    >
                      {props.confirmTransactionMessage}
                    </h5>
                  </div>
                </div>
                <div className="d-flex flex-row mb-4 ml-2">
                  <div style={{ marginRight: "35px" }}>
                    <Icon state={props.transactionComplete} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <h4 style={{ color: "white" }}>Confirm Transaction</h4> */}
                    <h5
                      style={{
                        fontSize: "16px",
                        color: "#818181",
                        fontWeight: "600",
                      }}
                    >
                      {props.transactionWait}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backdropFilter: "blur(20px)",
            backgroundColor: "transparent",
          }}
          className="modal-backdrop"
          onClick={props.toggleDialog}
        ></div>
      </div>
    </>
  );
}
