import { Outlet } from "react-router";
import axios from "axios";
import { useEffect,  useState } from "react";
const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

const GetData = () => {
  const [data, setData] = useState(null);

  async function onload() {
    let resp = await axios.get(BASE_URL_SERVER + "marketplace-data");
    if (resp.data) {
      setData(resp.data);
    }
  }
  useEffect(() => {
    onload();
  }, []);
  return <>{data && <Outlet context={[data]} />}</>;
};

export default GetData;
