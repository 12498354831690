import { Outlet } from "react-router";
import { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { goerli, mainnet, polygon, polygonMumbai } from "wagmi/chains";
import "@rainbow-me/rainbowkit/styles.css";
import { useOutletContext } from "react-router";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

const Layout = () => {
  const [data] = useOutletContext();
  const [network, updateNetwork] = useState();
  const [storeLogo, setStoreLogo] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [walletState, updateWalletState] = useState();
  const { chains, provider } = configureChains(
    [mainnet, polygon, goerli, polygonMumbai].filter(
      (chain) => chain.id === data.network
    ),
    [
      jsonRpcProvider({
        rpc: () => ({
          http: process.env.REACT_APP_POLYGON_TESTNET_RPC_URL,
        }),
      }),
      jsonRpcProvider({
        rpc: () => ({ http: process.env.REACT_APP_POLYGON_RPC_URL }),
      }),
      jsonRpcProvider({
        rpc: () => ({ http: process.env.REACT_APP_ETHEREUM_RPC_URL }),
      }),
      jsonRpcProvider({
        rpc: () => ({ http: process.env.REACT_APP_GOERLI_TESTNET_RPC_URL }),
      }),
      infuraProvider({ apiKey: process.env.REACT_APP_INFURA_API_KEY }),
      publicProvider(),
    ]
  );
  const projectId = "f3b15424590ae7cdc9609340682346c4";

  const { connectors } = getDefaultWallets({
    appName: "NFT Marketplace",
    projectId,
    chains,
  });
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  async function onload() {
    updateNetwork(data.network);

    let response = await axios.get(BASE_URL_SERVER + "branding-data");

    if (response.data.status) {
      if (response.data.logoUrl) {
        setStoreLogo(response.data.logoUrl);
      } else if (response.data.storeName) {
        setStoreName(response.data.storeName);
        setStoreLogo(null)
      }else{
        setStoreLogo(null)
      }

      if (response.data.title) {
        document.title = response.data.title;
      }

      if (response.data.favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = response.data.favicon;
      }

      if (response.data.metadataDescription) {
        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            response.data.metadataDescription
          );
        }
      }
    }
  }

  useEffect(() => {
    onload();
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        window.location.reload();
      });
    }
  }, [network, walletState]);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          initialChain={network}
          modalSize="compact"
          theme={darkTheme({
            accentColor: "#0E76FD",
            accentColorForeground: "white",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
          })}
        >
          <Navbar
            data={data}
            walletState={walletState}
            updateWalletState={updateWalletState}
            chainId={network}
            logo={storeLogo}
            name={storeName}
          ></Navbar>
          <Outlet context={[walletState]} />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
};

export default Layout;
