import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import CryptoAnimation from "./Animation";

const CheckDomain = () => {
  const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;
  const [isDomainExist, setIsDomainExist] = useState(null);

  const checkDomainExist = async () => {
    await axios.get(BASE_URL_SERVER + "check-domain-exist").then((response) => {
      if (response.data.status) {
        setIsDomainExist(true);
      } else {
        setIsDomainExist(false);
      }
    });
  };
  useEffect(() => {
    checkDomainExist();
  });
  return (
    <>
      {isDomainExist === true ? (
        <Outlet />
      ) : (
        isDomainExist === false && (
          <div>
            <CryptoAnimation />
            <div className="message-box">
              <h1>404</h1>
              <p>Site not found !</p>
              <div className="buttons-con">
                <div className="action-link-wrap">
                  <a href="https://console.hascoin.io" className="link-button">
                    Configure your NFT Marketplace
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CheckDomain;
