import NFTTile from "components/layouts/NFTTile";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import AdminPannel from "../admin/Admin";
import { getMetaData } from "util/checkTokenUri";
const ethers = require("ethers");

const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

export default function Marketplace() {
  const [data, updateData] = useState([]);
  const [dataFetched, updateFetched] = useState(false);
  const [isPolygon, updateIsPolygon] = useState();
  const [size, updateSize] = useState(0);
  const MarketplaceJSON = useRef({});

  // Function for fetching all the NFT'S related to a contract.
  async function getAllNFTs() {
    try {
      const result = await axios.get(
        `${BASE_URL_SERVER}listed-in-collection?address=${MarketplaceJSON.current.address}&chain=${MarketplaceJSON.current.network}&contractType=${MarketplaceJSON.current.contractType}`,
        { withCredentials: false }
      );

      const nfts = result.data.result;

      let numberOfNFTs = 0;
      //Fetch all the details of every NFT from the contract and display
      const items = await Promise.all(
        nfts.map(async (i) => {
          var meta = await getMetaData(i.token_uri);

          const price = ethers.utils.formatEther(i.price); // Convert Wei to Ether

          let item = {
            price,
            tokenId: i.token_id,
            seller: i.owner_of,
            image: meta.image,
            name: meta.name,
            description: meta.description,
          };
          numberOfNFTs += 1;
          return item;
        })
      );

      if (MarketplaceJSON.current.contractType === "deployed") {
        const res = await axios.get(BASE_URL_SERVER + "voucher-data");
        const voucher = res.data.voucher;

        await Promise.all(
          voucher.map(async (i) => {
            if (i.sold === false) {
              const tokenURI = i.uri;
              var meta = await getMetaData(tokenURI);

              const price = ethers.utils.formatEther(i.minPrice); // Convert Wei to Ether

              let voucherItem = {
                price,
                tokenId: i.tokenId,
                seller: i.signer,
                image: meta.image,
                name: meta.name,
                description: meta.description,
              };
              numberOfNFTs += 1;
              items.push(voucherItem);
            }
          })
        );
      }
      updateFetched(true);
      updateData(items);
      updateSize(numberOfNFTs);
    } catch (error) {
      console.log(error);
      await axios.get(BASE_URL_SERVER + "errorlog?er=" + error);
    }
  }

  async function onload() {
    let resp = await axios.get(BASE_URL_SERVER + "marketplace-data");

    if (resp.data) {
      if (resp.data.address) {
        MarketplaceJSON.current = resp.data;

        getAllNFTs();

        if (
          MarketplaceJSON.current.network ===
            Number(process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID) ||
          MarketplaceJSON.current.network ===
            Number(process.env.REACT_APP_POLYGON_CHAIN_ID)
        ) {
          updateIsPolygon(true);
        }
      } else {
        updateFetched(true);
        return;
      }
    }
  }

  useEffect(() => {
    onload();
  }, [MarketplaceJSON]);

  return (
    <div>
      {dataFetched ? (
        MarketplaceJSON.current.address ? (
          <div className="container-fluid my-5">
            <div className="card-section">
              <div className="container p-0">
                <div className="row" style={{ marginTop: "15px" }}>
                  {data.map((value, index) => {
                    return (
                      <NFTTile
                        data={value}
                        index={index}
                        key={index}
                        size={size}
                        isPolygon={isPolygon}
                      ></NFTTile>
                    );
                  })}
                  <div>
                    {data.length == 0 ? (
                      <div
                        className="mt-10 text-xl marketplace-message"
                        style={{ color: "white" }}
                      >
                        Nothing is availiable to display.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AdminPannel />
        )
      ) : (
        <div>
          <div>
            <div
              className="dottet-loader"
              style={{
                margin: "auto",
                marginTop: "22%",
                height: "100px",
                width: "100px",
                borderWidth: "12px",
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}
