import Swal from "sweetalert2";

export const checkError = (errorCode, network) => {
  const errorMap = (code) => {
    if (code == "ACTION_REJECTED") {
      return process.env.REACT_APP_ACTION_REJECTED;
    } else if (code == "INVALID_ARGUMENT") {
      return process.env.REACT_APP_INVALID_ARGUMENTS;
    } else if (code == "UNPREDICTABLE_GAS_LIMIT") {
      return process.env.REACT_APP_UNPREDICTABLE_GAS_LIMIT;
    } else if (code == -32603) {
      return process.env.REACT_APP_INSUFFCIENT_FUNDS;
    } else if (code == "CALL_EXCEPTION") {
      let chain = getNetworkName(network);
      return process.env.REACT_APP_WRONG_NETWORK_MESSAGE + chain;
    } else {
      return process.env.REACT_APP_SOMETHING_WENT_WRONG_MESSAGE;
    }
  };

  Swal.fire({
    icon: "error",
    color: "white",
    title: "OOPS...",
    text: errorMap(errorCode),
    background: "#34363d",
  });
};

export const getNetworkName = (network) => {
  if (network == 1) {
    return "Ethereum";
  } else if (network == 5) {
    return "Goerli Testnet";
  } else if (network == 137) {
    return "Polygon";
  } else {
    return "Polygon(Mumbai) Testnet";
  }
};
