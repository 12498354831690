import { Link } from "react-router-dom";

function NFTTile(props) {
  const newTo = {
    pathname: "/token/" + props.data.tokenId,
  };
  const previewlink = {
    pathname: "/create-nft",
  };

  return (
    <div
      className="card-dark card card-hover"
      style={{ marginTop: props.Preview ? "1rem" : "30px" }}
      id="card-hover"
    >
      <Link
        to={props.Preview ? previewlink : newTo}
        style={{
          textDecoration: "none",
          paddingLeft: props.Preview ? "10px" : "0",
          paddingRight: props.Preview ? "10px" : "0",
        }}
      >
        <div className="image-box">
          <img
            className="card-img-top img-card"
            src={props.data.image}
            alt="Card image cap"
          ></img>
        </div>
        <div className="card-body">
          <h5 className="card-title h-color text-truncate">
            {props.data.name}
          </h5>
          {props.isPolygon ? (
            <>
              <hr style={{ color: "white" }} />
              <p className="price text-truncate">
                {props.data.price} MATIC
                <span className="inline-display">
                  <p className="p-color fr">
                    {props.Preview ? (
                      <>1 of 1</>
                    ) : (
                      <>
                        {props.index + 1} of {props.size}
                      </>
                    )}
                  </p>
                </span>
              </p>
            </>
          ) : (
            <>
              <hr style={{ color: "white" }} />
              <p className="price text-truncate">
                {props.data.price} ETH
                <span className="inline-display">
                  <p className="p-color fr">
                    {props.index + 1} of {props.size}
                  </p>
                </span>
              </p>
            </>
          )}
        </div>
      </Link>
    </div>
  );
}

export default NFTTile;
