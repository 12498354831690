import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { CustomConnectButton } from "./connectButton";

export default function Navbar(props) {
  const [showCreateNFT, updateShowCreateNFT] = useState(false);
  const { address, isConnected } = useAccount();
  const { connector: activeConnector } = useAccount();

  async function onload() {
    try {
      if (
        props.data.contractType === "deployed" &&
        (props.data.admin == "Anyone" ||
          address.toUpperCase() == props.data.ownerAddress.toUpperCase())
      ) {
        updateShowCreateNFT(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }) => {
      if (account || chain) {
        props.updateWalletState(!props.walletState);
      }
    };

    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  useEffect(() => {
    onload();
  }, []);

  const location = useLocation();

  useEffect(() => {
    document.querySelectorAll(".nav-link").forEach((link) => {
      if (link.href === window.location.href) {
        link.classList.add("active");
      }
    });

    document.querySelectorAll(".active").forEach((link) => {
      if (link.href !== window.location.href) {
        link.classList.remove("active");
      }
    });
  }, [location]);

  return (
    <div className="container-fluid main-nav">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light my-2">
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="navbar-brand h-color logo">
              {props.logo !== false &&
                (props.logo ? (
                  <img className="logo-image" src={props.logo} />
                ) : props.name ? (
                  <h3
                    className="text-truncate"
                    style={{ fontSize: "1.35rem", width: "200px" }}
                  >
                    {props.name}
                  </h3>
                ) : (
                  <h3
                    className="text-truncate"
                    style={{ fontSize: "1.35rem", width: "200px" }}
                  >
                    Store Logo
                  </h3>
                ))}
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mo-nav">
              <li className="nav-item mr-30">
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className="nav-link p-color"
                >
                  Marketplace
                </Link>
              </li>
              {showCreateNFT && (
                <li className="nav-item mr-30">
                  <Link
                    to="/create-nft"
                    style={{ textDecoration: "none" }}
                    className="nav-link p-color"
                  >
                    Create NFT
                  </Link>
                </li>
              )}
              {isConnected && (
                <li className="nav-item mr-30">
                  <Link
                    to={`/profile/${address}`}
                    style={{ textDecoration: "none" }}
                    className="nav-link p-color profile"
                  >
                    Profile
                  </Link>
                </li>
              )}
            </ul>
            <CustomConnectButton updateWalletState={props.updateWalletState} />
          </div>
        </nav>
      </div>
      <hr style={{ color: "white", margin: "0", padding: "0" }} />
    </div>
  );
}
