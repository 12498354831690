//import './App.css';
import Marketplace from "./components/pages/marketplace/Marketplace";
import Profile from "./components/pages/userProfile/Profile";
import MintNFT from "./components/pages/createNft/MintNFT";
import NFTPage from "./components/pages/nftDetails/NFTpage";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import CheckMetamask from "./components/layouts/MetamaskNotFound";
import CheckDomain from "./components/layouts/MarketplaceNotFound";
import CheckContract from "components/layouts/ContractNotDeployed";
import PageNotFound from "components/layouts/PageNotFound";
import GetData from "components/layouts/GetData";

function App() {
  return (
    <Routes>
      <Route element={<CheckDomain />}>
        <Route element={<CheckContract />}>
          {/* <Route element={<CheckMetamask />}> */}
          <Route element={<GetData />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Marketplace />} />
              <Route path="/token/:tokenId" element={<NFTPage />} />
              <Route path="/profile/:walletAddress" element={<Profile />} />
              <Route path="/create-nft" element={<MintNFT />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
          {/* </Route> */}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
