import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import NFTTile from "components/layouts/NFTTile";
import PageNotFound from "components/layouts/PageNotFound";
import { getMetaData } from "util/checkTokenUri";
const ethers = require("ethers");

const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

export default function Profile() {
  const [data, updateData] = useState();
  const [dataFetched, updateFetched] = useState(false);
  const [countOfNFTs, updateNumberOfNFTs] = useState(0);
  const [isPolygon, updateIsPolygon] = useState(false);
  const [connectWalletMsg, updateConnectWalletMsg] = useState("");
  const { address, isConnected } = useAccount();
  const [pageNotFound, setPageNotFound] = useState(null);
  const MarketplaceJSON = useRef({});

  // Function to get NFT's that user own's.
  async function getNFTData(walletAddress) {
    try {
      let sumPrice = 0;

      const isValidAddress = ethers.utils.isAddress(walletAddress);

      if (isValidAddress) {
        updateConnectWalletMsg("");
        setPageNotFound(false);

        const result = await axios.get(
          `${BASE_URL_SERVER}get-wallet-nfts-in-collection?address=${MarketplaceJSON.current.address}&chain=${MarketplaceJSON.current.network}&walletAddress=${walletAddress}`,
          { withCredentials: false }
        );

        const nfts = result.data.result;

        let numberOfNFTs = 0;

        /*
         * Below function takes the metadata from tokenURI and the data returned by getMyNFTs() contract function
         * and creates an object of information that is to be displayed
         */
        const items = await Promise.all(
          nfts.map(async (i) => {
            var meta = await getMetaData(i.token_uri);

            const price = ethers.utils.formatEther(i.price); // Convert Wei to Ether

            let item = {
              price,
              tokenId: i.token_id,
              seller: i.owner_of,
              image: meta.image,
              name: meta.name,
              description: meta.description,
            };
            sumPrice += Number(price);
            numberOfNFTs += 1;

            return item;
          })
        );

        if (MarketplaceJSON.current.contractType === "deployed") {
          const res = await axios.get(BASE_URL_SERVER + "voucher-data");
          const voucher = res.data.voucher;

          await Promise.all(
            voucher.map(async (i) => {
              if (i.sold === false && walletAddress === i.signer) {
                const tokenURI = i.uri;
                var meta = await getMetaData(i.uri);

                let price = ethers.utils.formatUnits(
                  i.minPrice.toString(),
                  "ether"
                );
                let voucherItem = {
                  price,
                  tokenId: i.tokenId,
                  seller: i.signer,
                  image: meta.image,
                  name: meta.name,
                  description: meta.description,
                };
                sumPrice += Number(price);
                numberOfNFTs += 1;
                items.push(voucherItem);
              }
            })
          );
        }
        updateData(items);
        updateNumberOfNFTs(numberOfNFTs);
        updateFetched(true);
      } else {
        setPageNotFound(true);
        return;
      }
    } catch (error) {
      console.log(error);
      await axios.get(BASE_URL_SERVER + "errorlog?er=" + error);
    }
  }

  async function getMarketplaceData(walletAddress) {
    let resp = await axios.get(BASE_URL_SERVER + "marketplace-data");

    if (resp.data) {
      MarketplaceJSON.current = resp.data;
      if (
        resp.data.network ===
          Number(process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID) ||
        resp.data.network === Number(process.env.REACT_APP_POLYGON_CHAIN_ID)
      ) {
        updateIsPolygon(true);
      }
      getNFTData(walletAddress);
    } else {
      return;
    }
  }
  let { walletAddress } = useParams();

  useEffect(() => {
    if (!MarketplaceJSON.current.address) {
      getMarketplaceData(walletAddress);
    }
  }, [MarketplaceJSON]);

  useEffect(() => {
    if (MarketplaceJSON.current.address) {
      getNFTData(walletAddress);
    }
  }, [walletAddress]);

  return (
    <>
      {pageNotFound !== null && (
        <div>
          {!pageNotFound ? (
            <div className="container mb-5">
              <div className="row">
                <div className="col-3 profile-card">
                  <div className="card card-dark">
                    <div className="card-body">
                      <div className="profile-image">
                        <img
                          className="card-img-top img-card"
                          src="https://ipfs.hascoin.io/ipfs/QmcKupdyeuaxdNXLW787189698mwjY4aaANnJYcpUE7QJS"
                          alt="Card image cap"
                        />
                      </div>
                      <h3 className="h-color mt-3 text-center">User</h3>
                      <hr style={{ color: "aliceblue" }}></hr>
                      <p className="p-wallet">{address}</p>
                      {connectWalletMsg && (
                        <p style={{ color: "antiquewhite", marginLeft: "15%" }}>
                          {connectWalletMsg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {dataFetched ? (
                  <div className="col-9 profile-nfts">
                    <div className="row" style={{ color: "white" }}>
                      {data && (
                        <div className="row">
                          {data.map((value, index) => {
                            return (
                              <NFTTile
                                data={value}
                                index={index}
                                size={countOfNFTs}
                                key={index}
                                isPolygon={isPolygon}
                              ></NFTTile>
                            );
                          })}
                          <div>
                            {data.length != 0 ? (
                              ""
                            ) : (
                              <div>
                                <p
                                  className="marketplace-message"
                                  style={{
                                    background: "#464040",
                                    color: "rgb(155 238 255 / 88%)",
                                  }}
                                >
                                  No NFTs to display
                                </p>
                                <img
                                  src={require("images/noNFTimage.png")}
                                  style={{
                                    width: "500px",
                                    height: "500px",
                                    marginLeft: "20%",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="dottet-loader"
                    style={{
                      margin: "auto",
                      marginTop: "25%",
                      height: "100px",
                      width: "100px",
                      borderWidth: "12px",
                    }}
                  ></div>
                )}
              </div>
            </div>
          ) : (
            <PageNotFound />
          )}
        </div>
      )}
    </>
  );
}
