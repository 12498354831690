import React, { useState } from "react";

function ListPriceModal(props) {
  const [errorMessage, setErrorMessage] = useState("");

  function submitPrice() {
    if (props.price > 0) {
      props.submitPrice(props.isLazyMinted);
    } else {
      setErrorMessage("Price must br greater than 0");
    }
  }

  function handlePrice(e) {
    setErrorMessage("");
    props.handlePrice(e);
  }
  return (
    <>
      <div>
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            style={{ marginTop: "12%" }}
            className="modal-dialog"
            role="document"
          >
            <div
              className="modal-content shadow-lg"
              style={{
                backgroundColor: "#000",
                borderRadius: "1.5rem",
           
              }}
            >
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <h5 className="modal-title" style={{ color: "#EBEBED" }}>
                  Set Price
                </h5>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    fontSize: "25px",
                  }}
                  type="button"
                  className="close"
                  onClick={() => {
                    setErrorMessage("");
                    props.closeDialog();
                  }}
                >
                  <span style={{ color: "white" }} aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body" >
                <label
                  htmlFor="symbol"
                  style={{ marginBottom: "0.5rem", color: "white" }}
                >
                  Price
                  <span className="required-symbol">*</span>
                </label>
                <input
                  required
                  style={{}}
                  type="number"
                  className="form-control list-price-input"
                  name="price"
                  placeholder="NFT Price"
                  min={0}
                  value={props.price}
                  onChange={(e) => handlePrice(e)}
                />
              </div>
              <span
                className="error-text"
                style={{
                  fontSize: "14px",
                  color: "tomato",
                  marginLeft: "1rem",
                }}
              >
                {errorMessage}
              </span>
              <div className="modal-footer" style={{ borderTop: "none" }}>
                <button
                  style={{ width: "100%", padding: "1rem 1.8rem"}}
                  onClick={submitPrice}
                  type="button"
                  className="btn btn-primary"
                >
                  List NFT
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backdropFilter: "blur(20px)",
            backgroundColor: "transparent",
          }}
          className="modal-backdrop"
          onClick={props.toggleDialog}
        ></div>
      </div>
    </>
  );
}

export default ListPriceModal;
