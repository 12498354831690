import { ConnectButton } from "@rainbow-me/rainbowkit";
export const CustomConnectButton = (props) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks

        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        props.updateWalletState(connected);
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    style={{
                      background: "#0e76fd",
                      borderRadius: "12px",
                      padding: "6px 8px",
                      border: "0",
                      color: "white",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      openConnectModal();
                    }}
                    type="button"
                  >
                    Connect Wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    style={{
                      background: "#0e76fd",
                      borderRadius: "12px",
                      padding: "6px 8px",
                      border: "0",
                      color: "white",
                      fontWeight: "500",
                    }}
                    onClick={openChainModal}
                    type="button"
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: "flex", gap: 12 }}>
                  <button
                    onClick={openChainModal}
                    style={{
                      background: "#0d0d0d",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "12px",
                      padding: "6px 8px",
                      border: "0",
                    }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div>
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{
                              width: 24,
                              height: 24,
                              marginRight: "4px",
                            }}
                          />
                        )}
                        <svg
                          fill="none"
                          height="7"
                          width="14"
                          xmlns="http://www.w3.org/2000/svg"
                          color="white"
                        >
                          <path
                            d="M12.75 1.54001L8.51647 5.0038C7.77974 5.60658 6.72026 5.60658 5.98352 5.0038L1.75 1.54001"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            xmlns="http://www.w3.org/2000/svg"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </button>
                  <button
                    style={{
                      background: "#0d0d0d",
                      borderRadius: "12px",
                      padding: "6px 8px",
                      border: "0",
                      color: "white",
                      fontWeight: "500",
                    }}
                    onClick={openAccountModal}
                    type="button"
                  >
                    {account.displayName}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
