import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import CryptoAnimation from "./Animation";
import axios from "axios";
const CheckContract = () => {
  const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;
  const [isContractDeployed, setIsContractDeployed] = useState(null);

  const checkContractExist = async () => {
    await axios.get(BASE_URL_SERVER + "marketplace-data").then((response) => {
      if (response.data.address) {
        setIsContractDeployed(true);
      } else {
        setIsContractDeployed(false);
      }
    });
  };
  useEffect(() => {
    checkContractExist();
  });
  return (
    <>
      {isContractDeployed === true ? (
        <Outlet />
      ) : (
        isContractDeployed === false && (
          <div>
            <CryptoAnimation />

            <div className="message-box" style={{ left: "43%", top: "53%" }}>
              <h1 style={{ lineHeight: "120px" }}>Coming Soon!!</h1>
            </div>
          </div>
        )
      )}
    </>
  );
};
export default CheckContract;
