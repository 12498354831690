import React, { useState } from "react";
import CryptoAnimation from "./Animation";

const PageNotFound = () => {
  return (
    <>
      <div className="container">
        <div className="mt-4">
          <CryptoAnimation />
        </div>

        <div className="message-box" style={{ top: "58%" }}>
          <h1>404</h1>
          <p>Sorry! This page isn't available</p>
          <div className="buttons-con">
            <div className="action-link-wrap">
              <a href="/" type="button" className="link-button">
                Go To Marketplace
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
