import axios from "axios";

const checkTokenURI = (uri) => {
  var regex =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

  return regex.test(uri);
};

const getCIDFromIPFSURL = (ipfsURL) => {
  if (ipfsURL) {
    let match = ipfsURL.match(/ipfs:\/\/([a-zA-Z0-9]+)$/);
    if (match && match[1]) {
      return match[1];
    } else {
      return ipfsURL; // No match found
    }
  } else {
    return;
  }
};

export const getMetaData = async (tokenUri) => {
  let meta = {};
  if (checkTokenURI(tokenUri)) {
    await axios
      .get(tokenUri)
      .then((result) => {
        meta = result.data;
      })
      .catch((error) => {
        console.log(error);
        meta.image = null;
        meta.name = "";
        meta.description = "";
      });
  } else {
    let cid = getCIDFromIPFSURL(tokenUri);
    await axios
      .get(`https://ipfs.hascoin.io/ipfs/${cid}`, {
        withCredentials: false,
      })
      .then((result) => {
        meta = result.data;
      })
      .catch((error) => {
        meta.image = null;
        meta.name = "";
        meta.description = "";
      });
  }

  if (!checkTokenURI(meta.image)) {
    let cid = getCIDFromIPFSURL(meta.image);
    meta.image = `https://ipfs.hascoin.io/ipfs/${cid}`;
  }

  return meta;
};
