import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import VerifiedLogo from "svg-images/verified-logo";
import nftAbi from "artifacts/contracts/nftContract.sol/MyNFT.json";
import miniorangeMarketplaceAbi from "artifacts/contracts/miniorangeMarketplaceContract.sol/miniOrangeNftMarketplace.json";
import { useContract, useSigner, useAccount, useNetwork } from "wagmi";
import ListPriceModal from "./listPriceModal";
import ApprovalAndListingModal from "./approvalAndListingModal";
import { checkError, getNetworkName } from "util/metamaskErrors";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { getMetaData } from "util/checkTokenUri";
import copy from "copy-to-clipboard";
import ConfirmTransactionModal from "../createNft/ConfirmTransactionModal";
const ethers = require("ethers");
const BASE_URL_SERVER = process.env.REACT_APP_BASE_URL_SERVER;

export default function NFTPage() {
  const { address, isConnected, isDisconnected, isReconnecting } = useAccount();
  const [collectionName, setCollectionName] = useState(false);
  const [data, updateData] = useState(false);
  const [loader, updateloader] = useState(false);
  const [listPrice, setListPrice] = useState("");
  const [isPolygon, updateIsPolygon] = useState(false);
  const location = useLocation();
  const MarketplaceJSON = useRef({});
  const { data: signer } = useSigner();
  const { openConnectModal } = useConnectModal();
  const [royaltyPercentage, updateRoyaltyPercentage] = useState(false);
  const [marketplaceContractAddress, setMarketplaceContractAddress] =
    useState(null);
  const [priceDialog, setPriceDialog] = useState(false);
  const [approvalAndListingDialog, setApprovalAndListingDialog] =
    useState(false);
  const [approvalModal, updateApprovalModal] = useState(false);
  const [listingModal, updateListingModal] = useState(false);
  const [lazyMinted, setIsLazyMinted] = useState(false);
  const [metadataURL, setMetadataURL] = useState("");
  const [iscopy, setCopy] = useState(false);
  const [accountSignature, setSignature] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState(false);
  const [transactionModalHeading, setTransactionModalHeading] = useState();
  const [confirmTransactionMessage, setConfirmTransactionMessage] =
    useState("");
  const [transactionWaitMessage, setTransactionWaitMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopy(true);
  };
  function handlePrice(e) {
    const value = e.target.value;
    setListPrice(value);
  }
  function switchPriceDialog() {
    const value = !priceDialog;
    setPriceDialog(value);
  }

  function closeDialog() {
    setListPrice("");
    setPriceDialog(false);
  }

  function switchApprovalAndListingDialog() {
    const value = !approvalAndListingDialog;
    setApprovalAndListingDialog(value);
  }

  async function updateSell() {
    // check is listed
    switchPriceDialog();
  }

  async function submitPrice(value) {
    try {
      if (value === true) {
        const signerAddress = await signer.getAddress();

        var voucher = await CreateLazyMintVoucher(
          tokenId,
          signer,
          MarketplaceJSON.current.address,
          metadataURL
        );

        await axios
          .post(BASE_URL_SERVER + "update-voucher", {
            voucher,
            signer: signerAddress,
            tokenId: tokenId,
            accountSignature: accountSignature,
          })
          .then((response) => {
            switchPriceDialog();
            if (response.data.status) {
              Swal.fire({
                title: response.data.message,
                color: "white",
                icon: "success",
                background: "#34363d",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result) {
                  window.location.reload();
                }
              });
            }
          });
      } else if (value === "editListing") {
        switchPriceDialog();
        switchApprovalAndListingDialog();
        updateApprovalModal("loading");
        const approval = await checkIfApprove();

        if (approval === false) {
          await getApproval();
        }

        const price = ethers.utils.parseUnits(listPrice, "ether");
        updateApprovalModal("true");
        updateListingModal("loading");

        let editListing = await marketplaceContract
          .connect(signer)
          .editListing(MarketplaceJSON.current.address, tokenId, price);
        await editListing.wait();

        updateListingModal("true");
        setApprovalAndListingDialog(false);
        Swal.fire({
          title: "NFT Price Updated Successfully",
          color: "white",
          icon: "success",
          background: "#34363d",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result) {
            window.location.reload();
          }
          setListPrice(null);
          setPriceDialog(false);
          setApprovalAndListingDialog(false);
          updateApprovalModal(false);
          updateListingModal(false);
        });
      } else if (!value) {
        switchPriceDialog();
        switchApprovalAndListingDialog();
        updateApprovalModal("loading");
        const approval = await checkIfApprove();

        if (approval === false) {
          await getApproval();
        }

        const price = ethers.utils.parseUnits(listPrice, "ether");

        updateApprovalModal("true");
        updateListingModal("loading");

        await listNftOnMarketplace(price);

        updateListingModal("true");
        setApprovalAndListingDialog(false);

        Swal.fire({
          title: process.env.REACT_APP_NFT_LISTED_MESSAGE,
          color: "white",
          icon: "success",
          background: "#34363d",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result) {
            window.location.reload();
          }
          setListPrice(null);
          setPriceDialog(false);
          setApprovalAndListingDialog(false);
          updateApprovalModal(false);
          updateListingModal(false);
        });
      }
    } catch (error) {
      setListPrice(null);
      setPriceDialog(false);
      setApprovalAndListingDialog(false);
      updateApprovalModal(false);
      updateListingModal(false);
    }
  }

  // Function to get NFT data associated for a token ID.
  async function getdata(tokenId) {
    const collectionData = await axios.get(
      `${BASE_URL_SERVER}collection-name?nftAddress=${MarketplaceJSON.current.address}&chain=${MarketplaceJSON.current.network}`,
      { withCredentials: false }
    );

    setCollectionName(collectionData.data.collectionName);

    const result = await axios.get(
      `${BASE_URL_SERVER}nft-details?address=${MarketplaceJSON.current.address}&chain=${MarketplaceJSON.current.network}&tokenId=${tokenId}`,
      { withCredentials: false }
    );

    let connectedChain = getNetworkName(MarketplaceJSON.current.network);

    if (result.data.status) {
      const nft = result.data.result;

      var meta = await getMetaData(nft.token_uri);

      updateRoyaltyPercentage(nft.royalty);
      let price = ethers.utils.formatEther(nft.price); // Convert Wei to Ether

      let item = {
        price: price,
        tokenId: tokenId,
        seller: nft.owner_of,
        currentlyListed: nft.currentlyListed,
        image: meta.image,
        name: meta.name,
        description: meta.description,
        network: connectedChain,
      };

      updateData(item);
    } else {
      //token id does not exist
      if (MarketplaceJSON.current.contractType === "deployed") {
        const res = await axios.get(BASE_URL_SERVER + "voucher-data");
        const voucher = res.data.voucher;
        const index = voucher.findIndex((obj) => obj.tokenId === tokenId);
        setIsLazyMinted(true);
        const newVoucher = voucher[index];
        const tokenURI = await newVoucher.uri;
        setMetadataURL(tokenURI);
        let price = ethers.utils.formatUnits(
          newVoucher.minPrice.toString(),
          "ether"
        );
        updateRoyaltyPercentage(res.data.royalty);
        var meta = await getMetaData(tokenURI);

        let item = {
          price: price,
          tokenId: tokenId,
          seller: newVoucher.signer,
          currentlyListed: true,
          image: meta.image,
          name: meta.name,
          description: meta.description,
          network: connectedChain,
        };
        updateData(item);
      }
    }
  }

  const nftContract = useContract({
    address: MarketplaceJSON.current.address,
    abi: nftAbi.abi,
    signerOrProvider: signer,
  });

  const marketplaceContract = useContract({
    address: marketplaceContractAddress,
    abi: miniorangeMarketplaceAbi.abi,
    signerOrProvider: signer,
  });

  async function checkIfApprove() {
    let isApprovedForAll = await nftContract
      .connect(signer)
      .isApprovedForAll(data.seller, marketplaceContractAddress);

    if (!isApprovedForAll) {
      const getApproved = await nftContract
        .connect(signer)
        .getApproved(tokenId);
      if (
        getApproved.toUpperCase() !== marketplaceContractAddress.toUpperCase()
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async function getApproval() {
    let setApprovalForAll = await nftContract
      .connect(signer)
      .setApprovalForAll(marketplaceContractAddress, true);
    await setApprovalForAll.wait();
  }

  async function listNftOnMarketplace(price) {
    let listItem = await marketplaceContract
      .connect(signer)
      .listItem(MarketplaceJSON.current.address, tokenId, price);
    await listItem.wait();
  }

  async function CreateLazyMintVoucher(tokenId, signer, marketplace, uri) {
    try {
      const SIGNING_DOMAIN_NAME = "LazyNFT-Voucher";
      const SIGNING_DOMAIN_VERSION = "1";

      const chainId = await signer.getChainId();
      const contractAddress = marketplace;

      const domain = {
        name: SIGNING_DOMAIN_NAME,
        version: SIGNING_DOMAIN_VERSION,
        verifyingContract: contractAddress,
        chainId,
      };

      const types = {
        NFTVoucher: [
          { name: "tokenId", type: "uint256" },
          { name: "minPrice", type: "uint256" },
          { name: "uri", type: "string" },
        ],
      };

      const minPrice = ethers.utils.parseUnits(listPrice, "ether");
      const voucher = { tokenId, minPrice, uri };
      const signature = await signer._signTypedData(domain, types, voucher);

      const voucher_new = [
        tokenId,
        minPrice.toString(10),
        voucher.uri,
        signature,
      ];

      return voucher_new;
    } catch (err) {
      throw err;
    }
  }
  async function editListing(e) {
    e.preventDefault();
    setIsLazyMinted("editListing");
    switchPriceDialog();
  }

  async function updateLazyMintingVoucher(e) {
    e.preventDefault();

    try {
      let address = await signer.getAddress();
      await axios
        .get(BASE_URL_SERVER + "message?account=" + address)
        .then(async (result) => {
          if (result.data.status) {
            await signer.signMessage(result.data.message).then((result) => {
              setSignature(result);
            });
          }
        });

      switchPriceDialog();
    } catch (e) {}
  }

  // Function called when user want to de-list NFT
  async function removeListing(e) {
    e.preventDefault();
    try {
      Swal.fire({
        text: "Make sure to remove listings from other marketplaces also",
        color: "white",
        icon: "warning",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        background: "#34363d",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let removeListing = await marketplaceContract
            .connect(signer)
            .removeListing(MarketplaceJSON.current.address, tokenId);
          await removeListing.wait();

          window.location.reload();
        }
      });
    } catch (e) {}
  }
  // Function called when user buy NFT.
  async function buyNFT(tokenId) {
    if (isConnected) {
      try {
        updateloader(true);

        const result = await axios.get(
          `${BASE_URL_SERVER}nft-details?address=${MarketplaceJSON.current.address}&chain=${MarketplaceJSON.current.network}&tokenId=${tokenId}`,
          { withCredentials: false }
        );
        setOpenModal(true);
        setIsConfirmed("loading");
        setIsTransactionComplete("loading");
        setTransactionModalHeading("Buying the NFT");
        setConfirmTransactionMessage(
          "Please confirm the transaction in your wallet"
        );
        setTransactionWaitMessage("Buying this NFT");
        if (result.data.status) {
          let salePrice = ethers.utils.parseUnits(data.price, "ether");
          let transaction = await marketplaceContract
            .connect(signer)
            .buyItem(MarketplaceJSON.current.address, tokenId, {
              value: salePrice,
            });
          setIsConfirmed(true);
          await transaction.wait();
        } else {
          const res = await axios.get(BASE_URL_SERVER + "voucher-data");
          const voucher = res.data.voucher;
          const index = voucher.findIndex((obj) => obj.tokenId === tokenId);
          const newVoucher = voucher[index];
          const lazyMintVoucher = [
            tokenId,
            newVoucher.minPrice,
            newVoucher.uri,
            newVoucher.signature,
          ];
          const tx = await nftContract
            .connect(signer)
            .mintLazyVoucher(lazyMintVoucher, {
              value: newVoucher.minPrice,
            });
          setIsConfirmed(true);
          await tx.wait();
          await axios.put(BASE_URL_SERVER + "lazy-mint-voucher-status", {
            tokenId,
          });
        }

        setIsTransactionComplete(true);
        Swal.fire({
          title: process.env.REACT_APP_NFT_BOUGHT_MESSAGE,
          color: "white",
          icon: "success",
          background: "#34363d",
        }).then(async (result) => {
          if (result) {
            window.location.replace(location.pathname);
          }
        });
        updateloader(false);
      } catch (e) {
        checkError(e.code, MarketplaceJSON.current.network);
        updateloader(false);
        setIsConfirmed(false);
        setOpenModal(false);
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Not Connected !",
        color: "white",
        background: "#34363d",
        text: process.env.REACT_APP_WALLET_NOT_CONNECTED_MESSAGE,
        confirmButtonText: "Connect Wallet",
      }).then((result) => {
        if (result.isConfirmed) {
          openConnectModal();
        }
      });
    }
  }

  async function onload() {
    let resp = await axios.get(BASE_URL_SERVER + "marketplace-data");

    if (resp) {
      if (resp.data.address) {
        MarketplaceJSON.current = resp.data;
        await getdata(tokenId);

        if (
          MarketplaceJSON.current.network ===
            Number(process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID) ||
          MarketplaceJSON.current.network ===
            Number(process.env.REACT_APP_POLYGON_CHAIN_ID)
        ) {
          updateIsPolygon(true);
        }
        updateContractInfo();
      } else {
        return;
      }
    }
  }

  function updateContractInfo() {
    if (
      MarketplaceJSON.current.network ===
      Number(process.env.REACT_APP_POLYGON_CHAIN_ID)
    ) {
      setMarketplaceContractAddress(
        process.env.REACT_APP_MARKETPLACE_CONTRACT_POLYGON
      );
    } else if (
      MarketplaceJSON.current.network ===
      Number(process.env.REACT_APP_POLYGON_TESTNET_CHAIN_ID)
    ) {
      setMarketplaceContractAddress(
        process.env.REACT_APP_MARKETPLACE_CONTRACT_POLYGON_TESTNET
      );
    } else if (
      MarketplaceJSON.current.network ===
      Number(process.env.REACT_APP_ETHEREUM_CHAIN_ID)
    ) {
      setMarketplaceContractAddress(
        process.env.REACT_APP_MARKETPLACE_CONTRACT_ETHEREUM_MAINNET
      );
    } else if (
      MarketplaceJSON.current.network ===
      Number(process.env.REACT_APP_GOERLI_TESTNET_CHAIN_ID)
    ) {
      setMarketplaceContractAddress(
        process.env.REACT_APP_MARKETPLACE_CONTRACT_GOERLI
      );
    }
  }

  const params = useParams();
  const tokenId = params.tokenId;

  useEffect(() => {
    onload();
  }, [marketplaceContractAddress, signer, isConnected]);

  return (
    <div>
      {data ? (
        <div
          className="container"
          style={{ marginTop: "150px", marginBottom: "50px" }}
        >
          <div className="row">
            <div className="col-6">
              <div className="NftImage">
                <img
                  controls
                  autoPlay
                  src={data.image}
                  className="nft-image"
                  alt="..."
                  style={{ width: "100%" }}
                />
              </div>
              <div
                className="card mt-4 "
                style={{
                  background: "#262626",
                  color: "#c8c1c1",
                  borderColor: "grey",
                  borderRadius: "0",
                }}
              >
                <div className="ms-4 mt-3">
                  <h3 className="card-subtitle " style={{ color: "#cfd3e2" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-justify-left"
                      viewBox="0 0 16 16"
                      style={{ marginRight: "1rem" }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                    <i className="bi bi-justify-left"></i>
                    Description
                  </h3>
                </div>
                <hr style={{ color: "white", marginBottom: "0px" }} />
                <div className="nft-description">
                  <p
                    className="p-color text-justify "
                    style={{ wordBreak: "break-all" }}
                  >
                    {data.description}
                  </p>
                </div>
              </div>
              <div
                className="card mt-4 "
                style={{
                  background: "#262626",
                  color: "#c8c1c1",
                  borderColor: "grey",
                  borderRadius: "0",
                }}
              >
                <div className="mt-3 ms-4">
                  <h3 className="card-subtitle" style={{ color: "#cfd3e2" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-cassette"
                      viewBox="0 0 16 16"
                      style={{ marginRight: "1rem" }}
                    >
                      <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H7Z" />
                      <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13ZM1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.691l-1.362-2.724A.5.5 0 0 0 12 10H4a.5.5 0 0 0-.447.276L2.19 13H1.5a.5.5 0 0 1-.5-.5v-9ZM11.691 11l1 2H3.309l1-2h7.382Z" />
                    </svg>
                    <i className="bi bi-cassette"></i>
                    Details
                  </h3>
                </div>
                <hr style={{ color: "white" }} />

                <div className="mb-3" style={{ lineHeight: "2.3" }}>
                  <div className="nftDetails mx-4">
                    Contract address
                    <span
                      className="text-truncate copy"
                      style={{ maxWidth: "150px" }}
                      title={iscopy ? "Copied!" : "Copy to clipboard"}
                      onClick={() =>
                        copyToClipboard(MarketplaceJSON.current.address)
                      }
                    >
                      {MarketplaceJSON.current.address}
                    </span>
                  </div>
                  <div className="nftDetails mx-4">
                    Token id
                    <span
                      className="text-truncate"
                      style={{ maxWidth: "150px" }}
                    >
                      {tokenId}
                    </span>
                  </div>
                  <div className="nftDetails mx-4">
                    Owned by
                    <span
                      className="text-truncate"
                      style={{ maxWidth: "150px" }}
                    >
                      {address === data.seller ? "You" : data.seller}
                    </span>
                  </div>
                  <div className="nftDetails mx-4">
                    Chain
                    <span>{data.network}</span>
                  </div>
                  <div className="nftDetails mx-4">
                    Original Content
                    <span
                      className="text-truncate"
                      style={{ maxWidth: "150px" }}
                    >
                      <Link
                        to={data.image}
                        rel="nofollow noopener"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {data.image}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
            <div
              className="col-5 sticky-top"
              style={{ maxHeight: "500px", top: "120px", zIndex: "1" }}
            >
              <div className="col-12">
                <div className="row mx-2">
                  <p className="w-font mo-subheading">
                    {collectionName}&nbsp;
                    <VerifiedLogo />
                  </p>
                </div>
                <div className="row mx-2">
                  <div className="col-12" style={{ wordBreak: "break-all" }}>
                    <h3 className="w-font" style={{ padding: "5px" }}>
                      {" "}
                      {data.name}
                    </h3>
                  </div>
                  <div
                    className="d-flex flex-direction-row"
                    style={{ gap: "8px" }}
                  >
                    <h5
                      style={{
                        color: "grey",
                        margin: "0",
                        padding: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Royalties
                    </h5>
                    <p className="h-color royalty-percentage">
                      {royaltyPercentage}%
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 mx-4 my-3"
                style={{
                  wordBreak: "break-all",
                }}
              >
                <p style={{ margin: "0px", color: "grey", fontWeight: "bold" }}>
                  Current Owner
                </p>
                {data.seller && (
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    // id="copy"
                    className="text-truncate w-font copy"
                    title={iscopy ? "Copied!" : "Copy to clipboard"}
                    onClick={() => copyToClipboard(data.seller)}
                  >
                    {`${data.seller.slice(0, 6)}...${data.seller.slice(-4)}`}
                  </p>
                )}
              </div>
              <div
                className="col-12 round p-4"
                style={{ border: "solid 2px #34363d", borderRadius: "15px" }}
              >
                <div className="card-body product-card">
                  <h4 className="card-title h-color mb-3 text-center sub-text">
                    Price
                  </h4>
                  {isPolygon ? (
                    <h2 className="card-subtitle mb-3 text-muted text-center main-price">
                      {data.price} MATIC
                    </h2>
                  ) : (
                    <h2 className="card-subtitle mb-3 text-muted text-center main-price">
                      {data.price} ETH
                    </h2>
                  )}
                </div>
                {openModal && (
                  <ConfirmTransactionModal
                    confirmTransaction={isConfirmed}
                    confirmTransactionMessage={confirmTransactionMessage}
                    transactionWait={transactionWaitMessage}
                    transactionComplete={isTransactionComplete}
                    transactionModalHeading={transactionModalHeading}
                  />
                )}

                <div>
                  {data.currentlyListed ? (
                    address === data.seller ? (
                      <div>
                        {priceDialog && (
                          <ListPriceModal
                            toggleDialog={switchPriceDialog}
                            closeDialog={closeDialog}
                            submitPrice={submitPrice}
                            price={listPrice}
                            handlePrice={handlePrice}
                            isLazyMinted={lazyMinted}
                          />
                        )}
                        {approvalAndListingDialog && (
                          <ApprovalAndListingModal
                            approvalModal={approvalModal}
                            listingModal={listingModal}
                          />
                        )}
                        {lazyMinted === true ? (
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary action-button mt-4"
                              onClick={updateLazyMintingVoucher}
                            >
                              Edit Listing
                            </button>
                          </div>
                        ) : (
                          <div
                            className="d-flex flex-direction-row"
                            style={{ gap: "8px" }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary action-button mt-4"
                              onClick={removeListing}
                              style={{ padding: "15px 30px 15px 30px" }}
                            >
                              Cancel Listing
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary action-button mt-4"
                              onClick={editListing}
                              style={{ padding: "15px 30px 15px 30px" }}
                            >
                              Edit Listing
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {loader ? (
                          <div
                            className="dottet-loader"
                            style={{ margin: "auto", marginTop: "1.5rem" }}
                          ></div>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary action-button mt-4"
                            onClick={() => buyNFT(tokenId)}
                          >
                            Buy this NFT
                          </button>
                        )}
                      </>
                    )
                  ) : address === data.seller ? (
                    <div>
                      <>
                        {priceDialog && (
                          <ListPriceModal
                            toggleDialog={switchPriceDialog}
                            closeDialog={closeDialog}
                            submitPrice={submitPrice}
                            price={listPrice}
                            handlePrice={handlePrice}
                          />
                        )}
                        {approvalAndListingDialog && (
                          <ApprovalAndListingModal
                            approvalModal={approvalModal}
                            listingModal={listingModal}
                          />
                        )}
                        <button
                          className="btn btn-primary action-button mt-4"
                          onClick={() => updateSell(true)}
                        >
                          SellNFT
                        </button>
                      </>
                    </div>
                  ) : (
                    <button className="btn btn-primary action-button mt-4 disabled">
                      Sold Out
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="dottet-loader"
          style={{
            margin: "auto",
            marginTop: "22%",
            height: "100px",
            width: "100px",
            borderWidth: "12px",
          }}
        ></div>
      )}
    </div>
  );
}
